<template>
  <div class="" v-if="partnerInfo">
    <div class="pagenamPT">
      <h3 class="pagename2">{{ $t('front.common.partnerDetail') }}</h3>
    </div>
    <ul class="topInfo">
      <li class="bgA">
        <span>{{ $t('front.board.type') }}</span>
        <span>{{ partnerInfo.partnerLevelName }}</span>
      </li>
      <li class="bgB">
        <span>{{ $t('front.common.connectID') }}</span>
        <span>{{ partnerInfo.memId }}</span>
      </li>
      <li class="bgA">
        <span>{{ $t('front.common.nickName') }}</span>
        <span>{{ partnerInfo.memNick }}</span>
      </li>
      <li class="bgB">
        <span>{{ $t('front.cash.nav3') }}</span>
        <span>{{ thousand(partnerInfo.cashBalance || 0) }}</span>
      </li>
      <li class="bgC">
        <span>{{ $t('front.common.haveRolling') }}</span>
        <span>{{ thousand(partnerInfo.pointBalance || 0) }}</span>
      </li>
    </ul>

    <div class="tab-wrap">
      <a @click="onChangeType('partnerInfo')" class="tab-item" :class="{'active': type === 'partnerInfo'}">{{ $t('front.common.memberInfo') }}</a>
      <a @click="onChangeType('partnerRate')" class="tab-item" :class="{'active': type === 'partnerRate'}">{{ $t('front.common.rateInfo') }}</a>
      <a @click="onChangeType('partnerLog')" class="tab-item" :class="{'active': type === 'partnerLog'}">{{ $t('front.common.recentLogin') }}</a>
      <a @click="onChangeType('partnerMsg')" class="tab-item" :class="{'active': type === 'partnerMsg'}">{{ $t('front.common.note') }}</a>
      <a @click="onChangeType('partnerCash')" class="tab-item" :class="{'active': type === 'partnerCash'}">{{ $t('front.cash.changesInMoney') }}</a>
      <a @click="onChangeType('partnerRoll')" class="tab-item" :class="{'active': type === 'partnerRoll'}">{{ $t('front.common.rollingChange') }}</a>
    </div>

    <div class="tab-content">
      <div v-if="type == 'partnerInfo'">
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="info">
              <colgroup>
                <col width="20%">
                <col width="30%">
                <col width="20%">
                <col width="30%">
              </colgroup>
              <thead>
                <tr>
                  <th colspan="4">
                    <div class="tTitle">
                      <span>
                        {{ $t('front.common.memberDetailInfo') }}
                        <em>{{ partnerInfo.memId }}</em>
                        (<i>{{ partnerInfo.memNick }}</i>)
                      </span>
                      <a class="btn-save" @click="saveDetail">{{ $t('front.stributor.save') }}</a>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t('front.common.memId') }}</td>
                  <td>{{ partnerInfo.memId }}</td>
                  <td>{{ $t('front.common.nickName') }}</td>
                  <td><input type="text" v-model="partnerInfo.memNick" /></td>
                </tr>
                <tr>
                  <td>{{ $t('front.cash.nav3') }}</td>
                  <td>{{ thousand(partnerInfo.cashBalance || 0) }}</td>
                  <td>{{ $t('front.cash.nav4') }}</td>
                  <td>{{ thousand(partnerInfo.pointBalance || 0) }}</td>
                </tr>
                <tr>
                  <td>{{ $t('front.common.loginFunc') }}</td>
                  <td><input type="checkbox" class="set-switch" v-model="isLoginYn" @click="onChange('loginYn')"/></td>
                  <td>{{ $t('front.common.joinDay') }}</td>
                  <td>{{ partnerInfo.joinDate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
            <div class="strTablePTM">
              <ul>
                <li class="nodata">
                  <em>회원 상세 정보</em>
                  <div class="tTitle">
                    <span>
                      <em>{{ partnerInfo.memId }}</em>
                      (<i>{{ partnerInfo.memNick }}</i>)
                    </span>
                    <a class="btn-save" @click="saveDetail">저장</a>
                  </div>
                </li>
              </ul>
              <ul>
                <li><em>아이디</em>{{ partnerInfo.memId }}</li>
                <li><em>닉네임</em><input type="text" v-model="partnerInfo.memNick" /></li>
                <li><em>보유머니</em>{{ thousand(partnerInfo.cashBalance || 0) }}</li>
                <li><em>보유포인트</em>{{ thousand(partnerInfo.pointBalance || 0) }}</li>
                <li><em>로그인 가능</em><input type="checkbox" class="set-switch" v-model="isLoginYn" @click="onChange('loginYn')"/></li>
                <li><em>가입일</em><span class="txt">{{ partnerInfo.joinDate }}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type == 'partnerRate'">
        <div class="partnerTop start">
          <a class="btn-save" @click="onSaveRate">저장</a><!-- v-if="userData.partnerLevel === 'PTN_1'" -->
        </div>
        <div class="strTablescr">
          <div>
            <table class="info">
              <colgroup>
                <col width="20%">
                <col width="40%">
                <col width="40%">
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t('front.stributor.roll') }}</th>
                  <th>{{ $t('front.stributor.lose') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="memRollingInfo">
                  <template v-for="rate in memRollingInfo" :key="rate.cateCode">
                    <template v-if="rate.detailSetYn === 'N'">
                      <tr>
                        <td>{{ rate.cateCodeName }}</td>
                        <td>
                          <div class="rateInput">
                            <button type="button" class="min" @click="rate.pointRate = rate.minPointRate">{{ $t('front.common.minimum') }}<i>({{rate.minPointRate}})</i></button>
                            <input type="number" v-model="rate.pointRate" @change="onChangeRate(rate.pointRate, rate.maxPointRate, rate.minPointRate, rate, 'point')">
                            <button type="button" class="max" @click="rate.pointRate = rate.maxPointRate">{{ $t('front.common.maximum') }}<i>({{rate.maxPointRate}})</i></button>
                          </div>
                        </td>
                        <td>
                          <div class="rateInput">
                            <button type="button" class="min" @click="rate.loseRate = rate.minLoseRate">{{ $t('front.common.minimum') }}<i>({{rate.minLoseRate}})</i></button>
                            <input type="number" v-model="rate.loseRate" @change="onChangeRate(rate.loseRate, rate.maxLoseRate, rate.minLoseRate, rate, 'lose')">
                            <button type="button" class="max" @click="rate.loseRate = rate.maxLoseRate">{{ $t('front.common.maximum') }}<i>({{rate.maxLoseRate}})</i></button>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td>{{ rate.cateCodeName }}</td>
                        <td colspan="3">
                          <div class="rateInput">
                            <button type="button" @click="onOpenRateDetail(rate.cateCode)">요율세부설정</button>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="multipleRateInfo" class="rateDetail">
          <a class="close" @click="multipleRateInfo = null"><img src="@/assets/img/icon_cancelB.svg" /></a>
            <table>
              <template v-for="rate in multipleRateInfo['rateList']" :key="rate.rateType">
                <tr>
                  <td rowspan="2" class="bg">{{ rate.rateTypeName }}</td>
                  <td>롤링</td>
                  <td>
                    <div class="rateInput">
                      <button type="button" class="min" @click="rate.pointRate = rate.minPointRate">최소<i>( {{rate.minPointRate}} )</i></button>
                      <input type="number" v-model="rate.pointRate" @change="onChangeRate(rate.pointRate, rate.maxPointRate, rate.minPointRate, rate, 'point')">
                      <button type="button" class="max" @click="rate.pointRate = rate.maxPointRate">최대<i>( {{rate.maxPointRate}} )</i></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>루징</td>
                  <td>
                    <div class="rateInput">
                      <button type="button" class="min" @click="rate.loseRate = rate.minLoseRate">최소<i>( {{rate.minLoseRate}} )</i></button>
                      <input type="number" v-model="rate.loseRate" @change="onChangeRate(rate.loseRate, rate.maxLoseRate, rate.minLoseRate, rate, 'lose')">
                      <button type="button" class="max" @click="rate.loseRate = rate.maxLoseRate">최대<i>( {{rate.maxLoseRate}} )</i></button>
                    </div>
                  </td>
                </tr>
              </template>
            </table>
            <div class="rateInput">
              <button type="button" class="save" v-if="userData.partnerLevel === 'PTN_1'" @click="onSaveMultipleRate">저장</button>
            </div>
        </div>
      </div>
      <div v-if="type == 'partnerLog'">
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="log">
              <colgroup>
                <col width="8%">
                <col width="40%">
                <col width="12%">
                <col width="25%">
                <col width="15%">
              </colgroup>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('front.common.connectIP') }}</th>
                  <th>{{ $t('front.common.successOrfail') }}</th>
                  <th>{{ $t('front.common.connection') }}</th>
                  <th>{{ $t('front.common.block') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loginLog && loginLog.length > 0">
                  <template v-for="(log, idx) in loginLog" :key="log.accessDate">
                    <tr>
                      <td>{{idx + 1}}</td>
                      <td>{{ log.accessIp }}</td>
                      <td> - </td>
                      <td>{{ log.accessDate.replace('T', ' ') }}</td>
                      <td><a class="btn-save block">차단하기</a></td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="5">{{ $t('front.common.loginHistory') }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
            <div class="strTablePTM">
              <template v-if="loginLog && loginLog.length > 0">
                <template v-for="(log, idx) in loginLog" :key="log.accessDate">
                  <ul>
                    <li><em>#</em>{{idx + 1}}</li>
                    <li><em>접속 IP</em>{{ log.accessIp }}</li>
                    <li><em>성공여부</em> - </li>
                    <li><em>접속일시</em>{{ log.accessDate.replace('T', ' ') }}</li>
                    <li><em>차단</em><a class="btn-save block">차단하기</a></li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <ul>
                    <li class="nodata">{{ $t('front.common.loginHistory') }}</li>
                </ul>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type == 'partnerMsg'">
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="msg">
              <colgroup>
                <col width="10%">
                <col width="65%">
                <col width="10%">
                <col width="15%">
              </colgroup>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('front.search.content') }}</th>
                  <th>{{ $t('front.common.read') }}</th>
                  <th>{{ $t('front.board.regDate') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="msgLog && msgLog.length > 0">
                  <template v-for="msg in msgLog" :key="msg.msgIdx">
                    <tr>
                      <td>{{ msg.msgIdx }}</td>
                      <td><a @click="msg.isOpen = !msg.isOpen">{{ msg.msgTitle }}</a></td>
                      <td>{{ msg.msgStatus === 'READ' ? 'O' : 'X'}}</td>
                      <td>{{ msg.sendDate.replace('T', ' ')}}</td>
                    </tr>
                    <tr class="open" v-if="msg.isOpen">
                      <td colspan="5">
                        <pre class="pre">
                          {{msg.msgContents}}
                        </pre>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr><td colspan="4">{{ $t('front.common.noNote') }}</td></tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
            <div class="strTablePTM">
              <template v-if="msgLog && msgLog.length > 0">
                <template v-for="msg in msgLog" :key="msg.msgIdx">
                  <ul>
                    <li><em>#</em>{{ msg.msgIdx }}</li>
                    <li><em>내용</em><a @click="msg.isOpen = !msg.isOpen">{{ msg.msgTitle }}</a></li>
                    <li><em>읽음</em>{{ msg.msgStatus === 'READ' ? 'O' : 'X'}}</li>
                    <li><em>등록일시</em>{{ msg.sendDate.replace('T', ' ')}}</li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <ul>
                    <li class="nodata">{{ $t('front.common.noNote') }}</li>
                </ul>
              </template>
            </div>
          </div>
        </div>
        <!--div class="btn-wrap">
          <a class="btn-add">신규 쪽지 작성</a>
        </div-->
        <pagination v-if="msgLog"
                    :pageNum="msgLogPageInfo.page"
                    :pageSize="msgLogPageInfo.count_per_list"
                    :totalCount="msgLogPageInfo.tatal_list_count"
                    @goToPage="loadMsgLog"
        />
      </div>
      <div v-if="type == 'partnerCash'">
        <div class="partnerTop">
          <select v-model="reqData.cashLog.cashType">
            <option value="">{{ $t('front.gameCategory.all') }}</option>
            <option value="1">{{ $t('front.common.deposit') }}</option>
            <option value="-1">{{ $t('front.common.withdrawal') }}</option>
            <option value="2">{{ $t('front.common.managerPay') }}</option>
            <option value="-2">{{ $t('front.common.managerRetri') }}</option>
            <option value="-3">{{ $t('front.common.reservePay') }}</option>
            <option value="3">{{ $t('front.common.reserveRetrieve') }}</option>
            <option value="33">{{ $t('front.common.topPay') }}</option>
            <option value="-33">{{ $t('front.common.topRetri') }}</option>
            <option value="4">{{ $t('front.point.pointTrans') }}</option>
          </select>
          <date-filter-Pt :startDate="reqData.cashLog.startDate"
                          :endDate="reqData.cashLog.endDate"
                          @update="updateCashLogDate"
                          @search="loadCashLog" />
        </div>
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="msg">
              <colgroup>
                <col width="4%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
              </colgroup>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('front.common.before') }}</th>
                  <th>{{ $t('front.common.after') }}</th>
                  <th>{{ $t('front.common.increDecre') }}</th>
                  <th>{{ $t('front.common.sent') }}</th>
                  <th>{{ $t('front.common.receive') }}</th>
                  <th>{{ $t('front.common.change') }}</th>
                  <th>{{ $t('front.common.reference') }}</th>
                  <th>{{ $t('front.common.lastChange') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="cashLog && cashLog.length > 0">
                  <template v-for="log in cashLog" :key="log.cashIdx">
                    <tr>
                      <td>{{ log.cashIdx }}</td>
                      <td>{{ thousand(log.preCashAmt || 0) }}</td>
                      <td>{{ thousand(log.afterCashAmt || 0) }}</td>
                      <td>{{ thousand(log.cashAmt || 0) }}</td>
                      <td>{{ log.sender }}</td>
                      <td>{{ log.reciver }}</td>
                      <td>{{ CASH_TYPE_LIST(log.cashType) }}</td>
                      <td>{{ log.cashDesc }}</td>
                      <td>{{ log.updDate.replace('T', ' ') }}</td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr><td colspan="9">{{ $t('front.common.noChange') }}</td></tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
            <div class="strTablePTM">
              <template v-if="cashLog && cashLog.length > 0">
                <template v-for="log in cashLog" :key="log.cashIdx">
                  <ul>
                    <li><em>#</em>{{ log.cashIdx }}</li>
                    <li><em>변동 전</em>{{ thousand(log.preCashAmt || 0) }}</li>
                    <li><em>변동 후</em>{{ thousand(log.afterCashAmt || 0) }}</li>
                    <li><em>증감</em>{{ thousand(log.cashAmt || 0) }}</li>
                    <li><em>보낸곳</em>{{ log.sender }}</li>
                    <li><em>받는곳</em>{{ log.reciver }}</li>
                    <li><em>변동사유</em>{{ CASH_TYPE_LIST(log.cashType) }}</li>
                    <li><em>참고값</em>{{ log.cashDesc }}</li>
                    <li><em>최종변동일시</em>{{ log.updDate.replace('T', ' ') }}</li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <ul>
                    <li class="nodata">{{ $t('front.common.noChange') }}</li>
                </ul>
              </template>
            </div>
          </div>
        </div>
        <pagination v-if="cashLog"
                    :pageNum="cashLogPageInfo.page"
                    :pageSize="cashLogPageInfo.count_per_list"
                    :totalCount="cashLogPageInfo.tatal_list_count"
                    @goToPage="loadCashLog"
        />
      </div>
      <div v-if="type == 'partnerRoll'">
        <div class="partnerTop">
          <date-filter-Pt :startDate="reqData.rollingLog.startDate"
                          :endDate="reqData.rollingLog.endDate"
                          @update="updateRollingLogDate"
                          @search="loadRollingLog" />
        </div>
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="msg">
              <colgroup>
                <col width="10%">
                <col width="20%">
                <col width="45%">
                <col width="10%">
                <col width="15%">
              </colgroup>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('front.common.supplier') }}</th>
                  <th>{{ $t('front.common.gameName') }}</th>
                  <th></th>
                  <th>{{ $t('front.common.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="rollingLog && rollingLog.length > 0">
                  <template v-for="(log, index) in rollingLog" :key="log.updDate">
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <td>{{ log.vendorName }}</td>
                      <td>{{ log.gameName }}</td>
                      <td>{{ thousand(log.pointAmt || 0) }}</td>
                      <td>{{ log.updDate.replace('T', ' ') }}</td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr><td colspan="5">{{ $t('front.common.noConversion') }}</td></tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
            <div class="strTablePTM">
              <template v-if="rollingLog && rollingLog.length > 0">
                <template v-for="(log, index) in rollingLog" :key="log.updDate">
                  <ul>
                    <li><em>#</em>{{ index + 1 }}</li>
                    <li><em>공급사</em>{{ log.vendorName }}</li>
                    <li><em>게임 명</em>{{ log.gameName }}</li>
                    <li><em></em>{{ thousand(log.pointAmt || 0) }}</li>
                    <li><em>실행일시</em>{{ log.updDate.replace('T', ' ') }}</li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <ul>
                    <li class="nodata">{{ $t('front.common.noConversion') }}</li>
                </ul>
              </template>
            </div>
          </div>
        </div>
        <pagination v-if="rollingLog"
                    :pageNum="rollingLogPageInfo.page"
                    :pageSize="rollingLogPageInfo.count_per_list"
                    :totalCount="rollingLogPageInfo.tatal_list_count"
                    @goToPage="loadRollingLog"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DateFilterPt from '@/components/ui/DateFilterPt'
import Pagination from '@/components/ui/Pagination.vue'
import {
  partnerDetailCashLogV2,
  partnerDetailLoginV2, partnerDetailMsgV2,
  partnerDetailMultipleRateV2,
  partnerDetailRateV2, partnerDetailRollingLogV2,
  partnerDetailV2, savePartnerDetailMultipleRateV2,
  savePartnerDetailRateV2,
  savePartnerDetailV2
} from '@/api/v2/partner'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { CASH_TYPE_LIST } from '@/libs/constants'
export default {
  name: 'partnerDetail',
  components: {
    DateFilterPt,
    Pagination
  },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),
    isLoginYn () {
      return this.partnerInfo.loginYn === 'Y'
    }
  },
  watch: {
    'reqData.cashLog.cashType' () {
      this.loadCashLog()
    }
  },
  data () {
    return {
      type: 'partnerInfo',
      openMsg: true,
      rateDetail: false,
      memId: null,
      partnerInfo: null,
      memRollingInfo: null,
      multipleRateInfo: null,
      loginLog: null,
      msgLog: null,
      msgLogPageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 10
      },
      cashLog: null,
      cashLogPageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 10
      },
      rollingLog: null,
      rollingLogPageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 10
      },
      reqData: {
        cashLog: {
          cashType: '',
          startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
          endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
        },
        rollingLog: {
          startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
          endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
        }
      }
    }
  },
  async created () {
    console.log(this.userData)
    this.memId = this.$route.query.id
    this.loadDetail()
    this.loadRate()
    this.loadLoginLog()
    this.loadMsgLog()
    this.loadCashLog()
    this.loadRollingLog()
  },
  methods: {
    CASH_TYPE_LIST (type) {
      return CASH_TYPE_LIST[type]
    },
    thousand,
    updateRollingLogDate (value) {
      this.reqData.rollingLog.startDate = value.startDate
      this.reqData.rollingLog.endDate = value.endDate
    },
    loadRollingLog (page) {
      if (page && typeof page === 'number') {
        this.rollingLogPageInfo.page = page
      } else {
        this.rollingLogPageInfo.page = 1
      }
      const params = {
        memId: this.memId,
        ...this.reqData.rollingLog,
        page: this.rollingLogPageInfo.page
      }

      partnerDetailRollingLogV2(params).then(res => {
        const result = res.data
        console.log('partnerDetailRollingLogV2 : ', params, result)
        if (result.resultCode === '0') {
          this.rollingLog = result.data.rollingLog
          if (result.data.pageInfo) {
            this.rollingLogPageInfo = result.data.pageInfo
          }
        }
      })
    },
    updateCashLogDate (value) {
      this.reqData.cashLog.startDate = value.startDate
      this.reqData.cashLog.endDate = value.endDate
    },
    loadCashLog (page) {
      if (page && typeof page === 'number') {
        this.cashLogPageInfo.page = page
      } else {
        this.cashLogPageInfo.page = 1
      }

      const params = {
        memId: this.memId,
        ...this.reqData.cashLog,
        page: this.cashLogPageInfo.page
      }

      partnerDetailCashLogV2(params).then(res => {
        const result = res.data
        console.log('partnerDetailCashLogV2 : ', result)
        if (result.resultCode === '0') {
          this.cashLog = result.data.cashLog
          if (result.data.pageInfo) {
            this.cashLogPageInfo = result.data.pageInfo
          }
        }
      })
    },
    loadMsgLog (page) {
      if (page && typeof page === 'number') {
        this.msgLogPageInfo.page = page
      } else {
        this.msgLogPageInfo.page = 1
      }
      const params = {
        memId: this.memId,
        page: this.msgLogPageInfo.page
      }

      partnerDetailMsgV2(params).then(res => {
        const result = res.data
        console.log('partnerDetailMsgV2 : ', result)
        if (result.resultCode === '0') {
          this.msgLog = result.data.msgLog
          if (result.data.pageInfo) {
            this.msgLogPageInfo = result.data.pageInfo
          }
        }
      })
    },
    loadLoginLog () {
      const params = {
        memId: this.memId
      }

      partnerDetailLoginV2(params).then(res => {
        const result = res.data
        console.log('partnerDetailLoginV2 : ', result)
        if (result.resultCode === '0') {
          this.loginLog = result.data.loginLog
        }
      })
    },
    onSaveMultipleRate () {
      if (confirm('저장하시겠습니까?')) {
        const params = {
          siteId: this.partnerInfo.siteId,
          memId: this.partnerInfo.memId,
          vendorCode: this.multipleRateInfo.vendorCode,
          rateList: []
        }

        const rateList = []

        this.multipleRateInfo.rateList.forEach(item => {
          const data = {
            rateType: item.rateType,
            pointRate: item.pointRate.toString(),
            loseRate: item.loseRate.toString()
          }
          rateList.push(data)
        })

        params.rateList = rateList

        console.log(params)

        savePartnerDetailMultipleRateV2(params).then(res => {
          const result = res.data

          if (result.resultCode === '0') {
            alert('정상적으로 저장되었습니다.')
          } else {
            alert('다시 시도해주세요. ', result.resultMessage)
          }
        })
      }
    },
    onSaveRate () {
      if (confirm('저장하시겠습니까?')) {
        const params = {
          siteId: this.partnerInfo.siteId,
          memId: this.partnerInfo.memId,
          rateList: []
        }

        const rateList = []

        this.memRollingInfo.forEach(item => {
          const data = {
            category: item.category,
            cateCode: item.cateCode,
            pointRate: item.pointRate.toString(),
            loseRate: item.loseRate.toString()
          }
          rateList.push(data)
        })

        params.rateList = rateList

        console.log(params)

        savePartnerDetailRateV2(params).then(res => {
          const result = res.data

          if (result.resultCode === '0') {
            alert('정상적으로 저장되었습니다.')
          } else {
            alert('다시 시도해주세요. ', result.resultMessage)
          }
        })
      }
    },
    onChangeRate (value, max, min, item, type) {
      if (value > max) {
        alert('최대보다 큽니다.')
        item[type + 'Rate'] = max
      }
      if (value < min) {
        alert('최소보다 작습니다.')
        item[type + 'Rate'] = min
      }
    },
    onChange (type) {
      this.partnerInfo[type] = this.partnerInfo[type] === 'Y' ? 'N' : 'Y'
    },
    onOpenRateDetail (vendorCode) {
      const params = {
        memId: this.partnerInfo.memId,
        vendorCode: vendorCode
      }

      partnerDetailMultipleRateV2(params).then(res => {
        const result = res.data

        if (result.resultCode === '0') {
          console.log(result.data)
          this.multipleRateInfo = result.data.multipleRateInfo
        }
      })
    },
    onChangeType (type) {
      this.type = type
    },
    loadRate () {
      partnerDetailRateV2({ memId: this.memId }).then(res => {
        console.log('partnerDetailRateV2 : ', res)
        const result = res.data

        if (result.resultCode === '0') {
          console.log(result.data)
          this.memRollingInfo = result.data.memRollingInfo
        }
      })
    },
    saveDetail () {
      if (confirm('저장하시겠습니까?')) {
        const param = {
          memId: this.partnerInfo.memId,
          memNick: this.partnerInfo.memNick,
          loginYn: this.partnerInfo.loginYn
        }

        savePartnerDetailV2(param).then(res => {
          const result = res.data

          if (result.resultCode === '0') {
            alert('정상적으로 저장되었습니다.')
          } else {
            alert('다시 시도해주세요. ', result.resultMessage)
          }
        })
      }
    },
    loadDetail () {
      partnerDetailV2({ memId: this.memId }).then(res => {
        console.log('partnerDetailV2 : ', res)
        const result = res.data

        if (result.resultCode === '0') {
          console.log(result.data)
          this.partnerInfo = result.data.partnerInfo
        }
      })
    }
  }
}
</script>
<style scoped>
.pagenamPT .pagename2 {margin-bottom: 20px;font-size: 20px;font-weight: 900;}
.partnerTop {display: flex;align-items: center;justify-content: end;margin-bottom: 17px;}
.partnerTop select {height: 42px !important;width: 150px;margin: 0 15px 18px 0;border: 1px solid #dadde2 !important;font-size: 15px !important;}
.start {justify-content: flex-start !important;}
.topInfo {display: flex;align-items: center;gap: 10px;}
.topInfo li {width: 300px;height: 60px;border-radius: 7px;border: solid 1px #e0e0e0;box-sizing: border-box;padding-left: 22px;background-color: #fff;display: flex;flex-direction: column;justify-content: center;gap: 8px;font-size: 15px;color: #3b3b3b;}
.topInfo li.bgA {border-left: 7px solid #5bc0de;}
.topInfo li.bgB {border-left: 7px solid #f0ad4e;}
.topInfo li.bgC {border-left: 7px solid #d9534f;}
.topInfo li span {font-family: 'roboto';font-weight: 800;letter-spacing: -0.75px;}

.tab-wrap {display: flex;justify-content: flex-start;border-bottom: 4px solid #bdbfc4;box-sizing: border-box;margin-top: 30px;}
.tab-item:first-child {width: 124px;margin-left: 0;z-index: 5;}
.tab-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 134px;
  height: 33px;
  color: #979797;
  border-radius: 0 10px 0 0;
  font-size: 13px;
  user-select: none;
  cursor: pointer;
  position: relative;
  margin-left: -10px;
  box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.tab-item:nth-child(2) {z-index: 4;}
.tab-item:nth-child(3) {z-index: 3;}
.tab-item:nth-child(4) {z-index: 2;}
.tab-item:nth-child(5) {z-index: 1;}
.tab-item:last-child {z-index: 0;}
.tab-item:hover:not(.active), .tab-item.active {background-color: #bdbfc4;color: #fff;}

.tab-content {box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);border: solid 1px #d3d4d7;background-color: #fff;padding: 12px 10px;}
.tab-content table.info {width: 545px;}
.tab-content table {font-size: 12px;color: #585963;width: 100%;border: solid 1px #d8d8d8;background-color: #fff;}
.tab-content table thead {
    width: 100%;
    box-sizing: border-box;
}
.tab-content table th {
    padding: 5px 14px;
    text-align: center;
    box-sizing: border-box;
    height: 30px;
    background-color: #f3f4f9;
    border: solid 1px #d8d8d8;
    vertical-align: middle;
}
.tab-content table td {
    padding: 0 5px;
    height: 30px;
    border: solid 1px #c4c4c4;
    vertical-align: middle;
}
tr.total {background-color: #fcffaf !important;}
tr.total td {border: 0 !important;}
.rateInput {display: flex;align-items: center;justify-content: center;gap: 10px;}
.rateInput input {width: 50px;height: 22px;box-sizing: border-box;min-width: 50px !important;padding: 5px !important;font-size: 12px;}
.rateInput button {
  display: inline-block;
  padding: 4px 6px;
  color: #fff;
  font-size: 11px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-radius: 2px;
  border: solid 1px #000;
  box-sizing: border-box;
  background: #6f7172;
}
.rateInput .min i {color: #a4bbfd;}
.rateInput .max i {color: #ffa06e;}
.fx-center {display: flex;align-items: center;justify-content: center;gap: 10px;}
.tTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tTitle span {display: flex;align-items: center;font-size: 12px;color: #3d3d3d;font-weight: 900;}
.tTitle em {color: #876a97;margin: 0 10px;}
.tTitle i {color: #ff7e00;}
.btn-wrap {display: flex;margin: 176px 0 18px;}
.btn-save {
  height: 22px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px #000;
  background-color: #252930;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-save.block {background-color: #d91b1b;border: 0;}
.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  padding: 0px 17px;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #6457b8;color: #fff;font-size: 14px;cursor: pointer;}

.rateDetail {
  min-width: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  z-index: 10;
}
.rateDetail .close {margin-left: auto;}
.rateInput button.save {font-size: 15px;padding: 5px 12px;}

.tab-content table.log {width: 715px;text-align: center;font-weight: 800;}
.tab-content table.log th,
.tab-content table.log td {height: 45px;}
.tab-content table.log tr:nth-child(even) {background-color: #f3f4f9;}

.tab-content table.msg {width: 100%;text-align: center;font-weight: 800;}
.tab-content table.msg th,
.tab-content table.msg td {height: 45px;}
.tab-content table.msg tr:nth-child(even) {background-color: #f3f4f9;}
.tab-content table.msg tr.open {background-color: #fff;}
.msg .open .pre {padding: 15px 89px;text-align: left;line-height: 1.5em;}

.fx-center select {padding: 0 5px !important;}

input[type="text"], select {
  padding: 0 5px !important;
  height: 20px !important;
  border-radius: 2px;
  border: solid 1px #898989 !important;
  background-color: #fff;
  font-size: 12px !important;
  min-width: auto !important;
}
input[type="checkbox"].set-switch {
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  width: 35px;
  height: 15px !important;
  border-radius: 3em !important;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1) inset;
  border: solid 1px #b0b0b0;
  box-sizing: border-box;
  background-color: #eee;
  transition: background-color 0.15s ease-in-out;
  position: relative;
  max-width: 40px;
  min-width: auto !important;
  padding: 0 !important;
}
.set-switch:after {
  position: absolute;
  content: "";
  left: 0;
  width: 13px;
  height: 13px;
  background: #fff;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 2px 1px 5px 0px rgb(0 0 0 / 41%);
}
input[type="checkbox"].set-switch:checked {
  background: #15e41c;
}
input[type="checkbox"].set-switch:checked:after {
  left: calc(100% - 13px);
}
.strTablePC {display: block;}
.strTableM {display: none;}
.strTablePTM {width: 100%;font-size: 10px;}

.strTablePTM ul {margin-bottom: 5px;border-top: solid 1px #bdbfc4;border-bottom: solid 1px #bdbfc4;background: #FBFCFD;display: flex;flex-wrap: wrap;box-sizing: border-box;}
.strTablePTM li {width: 50%;display: flex;align-items: center;height: 35px;background: #FBFCFD;border-bottom: solid 1px #E9EAEF;box-sizing: border-box;}
.strTablePTM em {width: 70px;display: flex;align-items: center;justify-content: center;height: 35px;background: #EEF0F5;}
.strTablePTM li > div {padding: 0 5px;box-sizing: border-box;width: calc(100% - 70px);}
.strTablePTM li input {width: calc(100% - 75px);}
.nodata {width: 100% !important;font-size: 12px;justify-content: center;}
.nodata em {background: none;width: auto;margin: 0 5px 0 0;}
.nodata .tTitle {justify-content: normal;}
.nodata .btn-save {margin-left: auto;}
.txt {white-space: pre-wrap;line-height: 1.5em;width: calc(100% - 75px);}
@media (max-width: 1000px) {
  .pagenamPT .pagename2 {margin: 25px 0 15px;font-size: 20px;}
  .strTablescr {width: 100%;}
  .strTablescr > div {white-space: nowrap;overflow-x: auto;-webkit-overflow-scrolling: touch;-ms-overflow-style: -ms-autohiding-scrollbar;text-align: center;}
  .strTablePC {display: none;}
  .strTableM {display: block;}
  .topInfo {gap: 5px;}
  .topInfo li {width: 100%;padding-left: 10px;}
  .tab-content table {width: 100% !important;}
}
@media (max-width: 800px) {
  .tab-wrap {flex-wrap: wrap;background: #fff;}
  .tab-item {width: 33.33% !important;margin-left: 0;border-radius: 0;}
  .partnerTop {flex-direction: column;}
}
@media (max-width: 500px) {
  .topInfo {flex-wrap: wrap;}
  .topInfo li {width: calc(33.33% - 4px);}
}
</style>
